import React from "react";
import { Link } from "react-router-dom";

const Front = () => {
  let counter = JSON.stringify(localStorage.getItem("counter"));

  setInterval(() => {
    let counter = JSON.stringify(localStorage.getItem("counter"));
    console.log(counter);
  }, 7000);

  return (
    <div className="">
      {/* parent container - set color to the entire baground in below div*/}
      <div className="min-h-screen bg-gradient-to-br from-red-200 via-orange-200 to-red-400">
        {/* navigation bar */}
        <div className="flex shadow-lg ">
          <div className="flex-1 text-center font-bold text-3xl p-4 font-mono   backdrop-blur-lg backdrop-filter bg-red-500  text-white ">
            Furnace e-Supervisor
          </div>

          <div className="text-center font-bold text-xl p-2 font-sans backdrop-blur-lg backdrop-filter bg-red-500  text-white">
            <Link to="/">
              <button className="bg-red-50 p-2 rounded-lg text-black">
                Logout
              </button>
            </Link>
          </div>
        </div>
        {/* card containers */}
        <div className="flex flex-wrap  justify-center items-center m-5 mx-5">
          {/* Furnace 1 */}
          <Link to="/Container.js">
            <div className="max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-4 cursor-pointer flex-grow bg-white bg-opacity-25 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50">
              <div className="flex justify-center items-center">
                <img
                  className="w-2/3 pt-4 h-full"
                  src="./images/final.png"
                  alt="furnace"
                />
              </div>

              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-center text-black">
                  FURNACE - 1
                </div>
                <p className="text-black text-base text-center">750 Kw</p>
              </div>
            </div>
          </Link>

          {/* Furnace 2 */}
          <Link to="/F2sh1container.js">
            <div className="max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-4 cursor-pointer flex-grow bg-white bg-opacity-25 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50">
              <div className="flex justify-center items-center">
                <img
                  className="w-2/3 pt-4 h-full"
                  src="./images/final.png"
                  alt="furnace"
                />
              </div>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-center text-black">
                  FURNACE - 2
                </div>
                <p className="text-black text-base text-center">500 Kw</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="flex flex-wrap  justify-center items-center my-5 mx-5">
          <div className="max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-4 cursor-pointer flex-grow bg-red-500 text-white  shadow-yellow-500 ">
            <div className="flex justify-center items-center  pt-4 pb-4 h-full text-2xl font-serif">
              <h2 className="p-5 h-full font-bold font-serif ">
                Total Products Manufactured:{" "}
                <span className="font-extrabold pl-4">{counter}</span> Nos.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Front;
