import React, { useState, useEffect, handleSubmit, memo } from "react";
import Barchart from "./Barchart";
import { Link } from "react-router-dom";
import Token from "./Token";
import axios from "axios";
import Dec2bin from "./Dec2bin";
import Decrypt from "./Decrypt";
import { createContext } from "react";

const Container = memo(() => {
  let [tokenArr, setTokenArr] = useState([]);
  const [token, setToken] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [iter1, setIter1] = useState([]);
  const [iter2, setIter2] = useState([]);
  const [powerarr1, setPowerarr1] = useState([]);
  const [powerarr2, setPowerarr2] = useState([]);
  const [timedata1, setTimedata1] = useState([]);
  const [timedata2, setTimedata2] = useState([]);
  const [pdata1, setPdata1] = useState([]);
  const [pdata2, setPdata2] = useState([]);
  let [pfinal, setPfinal] = useState([]);
  let [tfinal, setTfinal] = useState([]);
  let [efinal, setefinal] = useState([]);
  let [opfinal, setopfinal] = useState([]);

  const [v1, setv1] = useState(0);
  const [v2, setv2] = useState(0);
  const [power1, setpower1] = useState([]);
  const [power2, setpower2] = useState([]);
  const [time1, setTime1] = useState([]);
  const [time2, setTime2] = useState([]);
  const [energy1, setEnergy1] = useState([]);
  const [energy2, setEnergy2] = useState([]);
  let [op1, setOp1] = useState([]);
  let [op2, setOp2] = useState([]);
  let [prodcount1, setProdcount1] = useState([]);
  let [prodcount2, setProdcount2] = useState([]);
  let [finalProductCount, setFinalProductCount] = useState([]);

  // op1 = [];
  // op2 = [];

  //const [edata2, setedata2] = useState([]);
  //const [edata1, setedata1] = useState([]);

  // token = JSON.parse(localStorage.getItem("token"));
  // setToken(token);
  // console.log("token", token);

  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  //efinal = [];
  // energy1 = [];
  //energy2 = [];
  //setCurrentTime(new Date());

  console.log(currentTime);

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;

    // Convert to 24-hour format
    hours = (hours % 12) + (isPM ? 12 : 0);

    // Format the time string
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }

  console.log(convertTo24Hour(currentTime)); // Output in 24 hr format: "22:30:45"

  function getPreviousDate(selectedDate) {
    const date = new Date(selectedDate);
    const previousDayTimestamp = date.getTime() - 24 * 60 * 60 * 1000;
    const previousDate = new Date(previousDayTimestamp);
    const formattedDate = previousDate.toISOString().slice(0, 10);
    return formattedDate;
  }

  //console.log(getPreviousDate(selectedDate));

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }
  console.log("selected date", selectedDate);
  console.log("prev date", getPreviousDate(selectedDate));

  //console.log(tokenArr[0]);

  //handle change
  const handleChange = (event) => {
    setSelectedDate(event.target.value);
  };

  //const handlesubmit function
  const handleSubmit = () => {
    const headers = {
      Authorization: `Bearer ${loginToken}`,
      "Content-Type": "application/json",
    };
    const data1 = {
      device_id: 22,
      // date: "2023-02-28",
      date: getPreviousDate(selectedDate),
    };
    const data2 = {
      device_id: 22,
      // date: "2023-03-01",
      date: selectedDate,
    };

    // console.log(headers);

    //previous date
    axios
      .post(
        "https://vidani.smart-iot.in/api/super-admin/device/getdevicelogs",
        data1,
        { headers }
      )
      .then(function (response) {
        const first = response.data;
        //console.log("from previous", first.data.logs[0].msg);
        const log1 = first.data.logs;
        let iter1 = log1.length;
        setIter1(iter1);
        //console.log(iter1);
        let v2 = 0;
        let pdata2 = [];
        let timedata2 = [];
        let power2 = [];
        let energy2 = [];
        let time2 = [];
        let op2 = [];
        let edata2 = [];
        let op2arr = [];
        let opdata2 = [];
        let prodcount2 = [];
        let pc2 = [];
        // let pfinal = [];
        for (let i = 0; i < iter1; i++) {
          //console.log("previous", first.data.logs[i].msg);

          const powerstring2 = first.data.logs[i].msg;
          const powerarr2 = stringToArray(powerstring2);
          setPowerarr2(powerarr2);
          //console.log(powerarr);
          //console.log("current", second.data.logs[i].msg);

          const timestring2 = new Date(
            first.data.logs[i].log_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });
          //console.log(timestring2);
          timedata2.push(convertTo24Hour(timestring2));

          const result = [];
          let st = Number(powerarr2[168]);
          let nd = Number(powerarr2[169]);
          let rd = Number(powerarr2[170]);
          let th = Number(powerarr2[171]);

          Number(
            result.push(
              Decrypt(Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd)))
            )
          ).toFixed(2);

          let rdata2 = Number(result[0]).toFixed(0);
          pdata2.push(rdata2);

          /*edata starts here */

          const energyresult = [];
          st = Number(powerarr2[0]);
          nd = Number(powerarr2[1]);
          rd = Number(powerarr2[2]);
          th = Number(powerarr2[3]);

          Number(
            energyresult.push(
              Decrypt(Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd)))
            )
          ).toFixed(2);

          let e = Number(energyresult[0]).toFixed(0);
          edata2.push(e);

          /*edata ends here */

          if (pdata2[i] === "NaN") {
            pdata2[i] = "0";
          }

          if (rdata2 > 500) {
            v2++;
          }
          setv2(v2);
          //console.log(result);

          // operating mode 1st request
          let cm = Number(powerarr2[352]) * 256 + Number(powerarr2[353]);
          let hold = Number(powerarr2[354]) * 256 + Number(powerarr2[355]);
          let bt = Number(powerarr2[356]) * 256 + Number(powerarr2[357]);
          let bd = Number(powerarr2[358]) * 256 + Number(powerarr2[359]);
          let shutd = Number(powerarr2[360]) * 256 + Number(powerarr2[361]);
          let oth = Number(powerarr2[362]) * 256 + Number(powerarr2[363]);

          // op2arr[0] = cm;
          // op2arr[1] = hold;
          // op2arr[2] = bt;
          // op2arr[3] = bd;
          // op2arr[4] = shutd;
          // op2arr[5] = oth;
          op2arr = [cm, hold, bt, bd, shutd, oth];

          opdata2.push(op2arr);

          //operating mode cal ends

          /**product count */
          let value1 = Number(powerarr2[384]) * 256 + Number(powerarr2[385]);
          let value2 = Number(powerarr2[386]) * 256 + Number(powerarr2[387]);
          let finalCount = Number(value1) * 65536 + Number(value2);
          let counter = Math.round(finalCount / 2);
          // console.log(counter);

          pc2.push(counter);

          /**product count */

          if (timedata2[i] >= "20:00:00" && timedata2[i] < "24:00:00") {
            //console.log(i);
            //console.log(pdata2[i]);
            power2.push(pdata2[i]);
            time2.push(timedata2[i]);
            energy2.push(edata2[i]);
            op2.push(opdata2[i]);
            prodcount2.push(pc2[i]);
          }
        }
        //console.log(v2);
        setPdata2(pdata2);
        console.log(pdata2);
        setTimedata2(timedata2);
        //console.log(timedata2);
        setpower2(power2);
        //console.log(power2);
        setTime2(time2);
        setEnergy2(energy2);
        setOp2(op2);
        console.log(op2);
        setProdcount2(prodcount2);
        console.log(prodcount2);
        //console.log(time2);
        // console.log(pfinal);
        //console.log(edata2);
        // console.log("energy2", energy2);
      })
      .catch(function (error) {
        console.log(error);
      });

    //curent date

    axios
      .post(
        "https://vidani.smart-iot.in/api/super-admin/device/getdevicelogs",
        data2,
        { headers }
      )
      .then(function (response) {
        const second = response.data;
        //console.log("current", second.data.logs[0].msg);
        const log2 = second.data.logs;
        let iter2 = log2.length;
        setIter2(iter2);

        if (log2) {
          localStorage.setItem("log2", JSON.stringify(log2));
        }
        //console.log(localStorage.getItem("log2"));
        //console.log(iter2);

        let v1 = 0;
        let pdata1 = [];
        let timedata1 = [];
        let power1 = [];
        let time1 = [];
        let edata1 = [];
        let energy1 = [];
        let op1arr = [];
        let opdata1 = [];
        let op1 = [];
        let prodcount1 = [];
        let pc1 = [];

        for (let i = 0; i < iter2; i++) {
          const powerstring1 = second.data.logs[i].msg;
          const powerarr1 = stringToArray(powerstring1);
          setPowerarr1(powerarr1);

          const timestring1 = new Date(
            second.data.logs[i].log_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });

          timedata1.push(convertTo24Hour(timestring1));

          const result = [];
          let st = Number(powerarr1[168]);
          let nd = Number(powerarr1[169]);
          let rd = Number(powerarr1[170]);
          let th = Number(powerarr1[171]);

          Number(
            result.push(
              Decrypt(Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd)))
            )
          ).toFixed(2);

          let rdata = Number(result[0]).toFixed(0);
          pdata1.push(rdata);

          /*edata starts here */

          const energyresult = [];
          st = Number(powerarr1[0]);
          nd = Number(powerarr1[1]);
          rd = Number(powerarr1[2]);
          th = Number(powerarr1[3]);

          Number(
            energyresult.push(
              Decrypt(Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd)))
            )
          ).toFixed(2);

          let e = Number(energyresult[0]).toFixed(0);
          edata1.push(e);

          /*edata ends here */

          if (pdata1[i] === "NaN") {
            pdata1[i] = "0";
          }

          if (rdata > 500) {
            v1++;
          }
          setv1(v1);
          //console.log(result);

          // operating mode 1st request
          let cm = Number(powerarr1[352]) * 256 + Number(powerarr1[353]);
          let hold = Number(powerarr1[354]) * 256 + Number(powerarr1[355]);
          let bt = Number(powerarr1[356]) * 256 + Number(powerarr1[357]);
          let bd = Number(powerarr1[358]) * 256 + Number(powerarr1[359]);
          let shutd = Number(powerarr1[360]) * 256 + Number(powerarr1[361]);
          let oth = Number(powerarr1[362]) * 256 + Number(powerarr1[363]);

          // op1arr[0] = cm;
          // op1arr[1] = hold;
          // op1arr[2] = bt;
          // op1arr[3] = bd;
          // op1arr[4] = shutd;
          // op1arr[5] = oth;
          op1arr = [cm, hold, bt, bd, shutd, oth];

          opdata1.push(op1arr);

          //operating mode cal ends

          /** product count*/
          let value1 = Number(powerarr1[384]) * 256 + Number(powerarr1[385]);
          let value2 = Number(powerarr1[386]) * 256 + Number(powerarr1[387]);
          let finalCount = Number(value1) * 65536 + Number(value2);
          let counter = Math.round(finalCount / 2);
          // console.log(counter);

          pc1.push(counter);
          /** product count */

          if (timedata1[i] <= "08:00:00" && timedata1[i] > "00:00:00") {
            power1.push(pdata1[i]);
            time1.push(timedata1[i]);
            energy1.push(edata1[i]);
            op1.push(opdata1[i]);
            prodcount1.push(pc1[i]);
          }
        }
        //console.log(v1);
        setPdata1(pdata1);
        console.log(pdata1);
        setTimedata1(timedata1);
        //console.log(timedata1);
        setpower1(power1);
        //console.log(power1);
        setTime1(time1);
        setEnergy1(energy1);
        setOp1(op1);
        console.log(op1);
        setProdcount1(prodcount1);
        console.log(prodcount1);
        //console.log(time1);
        // setPfinal(pfinal);
        // console.log(pfinal);
        //console.log(edata1);
        // console.log("energy1", energy1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (loginToken.length > 0) {
      handleSubmit();
    }
  }, [tokenArr, selectedDate]);

  // console.log(tokenArr);
  // if (tokenArr) {
  //   localStorage.setItem("tokenArr", JSON.stringify(tokenArr));
  // }
  // console.log(localStorage.getItem("tokenArr"));

  pfinal = [...power2, ...power1];
  if (pfinal) {
    localStorage.setItem("pfinal", JSON.stringify(pfinal));
  }
  // console.log(localStorage.getItem("pfinal"));
  //console.log(pfinal);
  tfinal = [...time2, ...time1];

  if (tfinal) {
    localStorage.setItem("tfinal", JSON.stringify(tfinal));
  }

  efinal = [...energy2, ...energy1];
  console.log(efinal);

  finalProductCount = [...prodcount2, ...prodcount1];
  console.log(finalProductCount);

  let displayCount =
    finalProductCount[finalProductCount.length - 1] - finalProductCount[0];
  console.log(displayCount);

  if (powerarr1) {
    localStorage.setItem("powerarr1", JSON.stringify(powerarr1));
  }

  opfinal = [...op2, ...op1];
  let cyclemodearr = [];
  let pouringmodearr = [];

  for (let i = 0; i < opfinal.length; i++) {
    cyclemodearr.push(opfinal[i][0]);
    pouringmodearr.push(opfinal[i][1]);
  }
  console.log(opfinal);
  console.log(cyclemodearr);
  console.log(pouringmodearr);

  if (opfinal) {
    localStorage.setItem("opfinal", JSON.stringify(opfinal));
  }

  if (selectedDate) {
    localStorage.setItem("selectedDate", JSON.stringify(selectedDate));
  }
  console.log(localStorage.getItem("selectedDate"));

  /**** operating mode analysis calculations*****/
  let cycleCount = 0;
  let pouringCount = 0;
  let btCount = 0;
  let bdCount = 0;
  let shutdCount = 0;
  let othCount = 0;
  for (let i = 0; i < opfinal.length; i++) {
    if (opfinal[i][0] > 500) {
      cycleCount++;
    }
    if (opfinal[i][1] > 500) {
      pouringCount++;
    }
    if (opfinal[i][2] > 500) {
      btCount++;
    }
    if (opfinal[i][3] > 500) {
      bdCount++;
    }
    if (opfinal[i][4] > 500) {
      shutdCount++;
    }
    if (opfinal[i][5] > 500) {
      othCount++;
    }
  }
  console.log(cycleCount);
  console.log(pouringCount);
  console.log(btCount);
  console.log(bdCount);
  console.log(shutdCount);
  console.log(othCount);
  /**** operating mode analysis ends here *****/

  // console.log(localStorage.getItem("powerarr1"));
  //console.log(efinal);

  // console.log(tfinal);
  // console.log(localStorage.getItem("tfinal"));

  //const mycontext = createContext(pfinal, tfinal);
  // console.log(mycontext);
  // console.log(data);
  let [resultlatest, setresultlatest] = useState([]);
  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  console.log(resultlatest);
  // console.log(JSON.parse(localStorage.getItem("resultlatest")));
  // localStorage.getItem("resultlatest");
  // console.log(resultlatest);
  // let [operatingMode, setOperatingmode] = useState([]);
  let [operatingMode, setOperatingmode] = useState([]);
  operatingMode = JSON.parse(localStorage.getItem("operatingMode")) || [];
  console.log(operatingMode);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedOperatingMode = JSON.parse(
        localStorage.getItem("operatingMode")
      );
      setOperatingmode(updatedOperatingMode);
    };
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [operatingMode]);

  let currentMode = [
    "Cycle Mode", //green
    "Pouring Mode", //,safron
    "Breaktime", //yellow
    "Breakdown Mode", //red dark
    "Shutdown Mode", //red light
    "Others", //blue
  ];
  let mode;
  if (operatingMode.length !== 0) {
    for (let i = 0; i < operatingMode.length; i++) {
      if (operatingMode[i] > 500) {
        console.log(currentMode[i]);
        mode = currentMode[i];
      }
    }
  }
  console.log(mode);

  let [energy, setEnergy] = useState();
  let [vavg, setVavg] = useState("");
  let [iavg, setIavg] = useState("");
  let [pf, setPf] = useState("");
  let [freq, setFreq] = useState("");
  let [pow, setPow] = useState("");

  energy = resultlatest[0];
  vavg = resultlatest[20];
  iavg = resultlatest[28];
  pf = resultlatest[33];
  freq = resultlatest[35];
  pow = resultlatest[42];

  setInterval(() => {
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let operatingMode = JSON.parse(localStorage.getItem("operatingMode"));
    energy = resultlatest[0];
    vavg = resultlatest[20];
    iavg = resultlatest[28];
    pf = resultlatest[33];
    freq = resultlatest[35];
    pow = resultlatest[42];
    setEnergy(energy);
    setVavg(vavg);
    setFreq(freq);
    setPow(pow);
    setIavg(iavg);
    setPf(pf);
    setOperatingmode(operatingMode);
    // console.log(energy, vavg, pf, iavg, freq, pow);
  }, 10000);

  console.log(energy, vavg, pf, iavg, freq, pow);

  let activeCounter = 0;
  let idleCounter = 0;
  let breakdownCounter = 0;

  let energyPerMinute = [];
  let energyPerHour = [];

  for (let i = 0; i < pfinal.length; i++) {
    if (pfinal[i] > 500) {
      activeCounter++;
    } else if (pfinal[i] > 1 && pfinal[i] < 500) {
      idleCounter++;
    } else {
      breakdownCounter++;
    }

    if (i < pfinal.length - 1) {
      energyPerMinute.push(efinal[i + 1] - efinal[i]);
    }
  }

  let timeHour = [];

  for (let i = 0; i < pfinal.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, pfinal.length - 1);
    energyPerHour.push(efinal[end] - efinal[start]);
    timeHour.push(tfinal[end]);
  }

  console.log(energyPerMinute);
  console.log(energyPerHour);
  console.log(timeHour);

  console.log(activeCounter, idleCounter, breakdownCounter);

  let eindex = efinal.length - 1;

  let energyConsumed = efinal[eindex] - efinal[0];
  //console.log(efinal[eindex]);
  //console.log(energyConsumed);

  /*SHIFT-I POWER-pfinal, ENERGY-efinal, TIME-tfinal */

  let buttonClass = "";
  switch (mode) {
    case "Cycle Mode":
      buttonClass = "bg-green-500 text-white shadow-green-300/30 ";
      break;
    case "Pouring Mode":
      buttonClass = "bg-orange-500 shadow-yellow-500/40";
      break;
    case "Breaktime":
      buttonClass = "bg-yellow-500 shadow-orange-300/30 ";
      break;
    case "Breakdown Mode":
      buttonClass = "bg-red-500 shadow-yellow-500/30 text-white";
      break;
    case "Shutdown Mode":
      buttonClass = "bg-red-400 shadow-orange-300/30 ";
      break;
    case "Others":
      buttonClass = "bg-blue-500 shadow-blue-500/30 text-white ";
      break;
    default:
      buttonClass = "bg-blue-400 ";
      mode = "Device Inactive";
  }

  return (
    <div>
      {/* <Token setTokenArr={setTokenArr} /> */}
      <div className="bg-gray-100 2xl:h-1/6 p-5  shadow-blue-500/50 m-2 rounded-lg shadow-lg">
        <div className="flex justify-center align-middle flex-wrap">
          {/* <div>
            <img src={icon} alt="logo" className="w-12 h-12 mr-2" />
          </div> */}

          <div className=" pt-2 ease-out transform hover:scale-110 transition duration-500 font-semibold 2xl:text-xl text-lg m-2">
            <h2 className="text-center ml-5 font-sans font-semibold">
              FURNACE - 1 (750 Kw)
            </h2>
          </div>

          <div>
            <form className="flex flex-1 flex-col sm:flex-row sm:gap-4">
              <label htmlFor="datepicker" className="sr-only">
                Select Date
              </label>
              <input
                type="date"
                id="datepicker"
                // defaultValue={date}
                value={selectedDate}
                onChange={handleChange}
                className="px-3 py-2 mt-2 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
              />
            </form>
          </div>
          <div className="flex flex-wrap justify-center ">
            <div>
              <Link to="/Container.js">
                <button className="p-2 flex-1 bg-red-700 text-center font-bold text-lg m-2 ease-out transform hover:scale-110 transition duration-500 hover:bg-green-900 text-white rounded-lg">
                  Shift-I
                </button>
              </Link>
            </div>
            <div>
              <Link to="/Sh2Container.js">
                <button className="p-2 flex-1 bg-green-400 text-center font-bold text-lg m-2 ease-out transform hover:scale-110 transition duration-500 hover:bg-green-900 text-black hover:text-white rounded-lg">
                  Shift-II
                </button>
              </Link>
            </div>
            <div>
              <Link to="/Front.js">
                <button className="p-2 flex-1 bg-indigo-600 text-center font-bold text-lg m-2 hover:bg-red-500 text-white hover:text-white rounded-lg">
                  Home
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* container2 (wrapper)-this is dividing the oage in 2 parts*/}
      <div className="flex flex-wrap bg-gray-100">
        {/* my piechart container */}
        <div className="bg-white-500 p-8 flex-1 shadow-blue-500/50 m-2 rounded-lg shadow-lg">
          {/* <Gaugechart /> */}
          {/* <div id="data"></div> */}
          <h2 className="text-center font-bold text-lg">Real Time Data</h2>
          <div className="flex flex-wrap justify-center items-center">
            <button
              className={`flex-1 p-8 m-2 rounded-lg ${buttonClass} text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 overflow-hidden text-2xl font-serif`}
            >
              {mode}
            </button>
          </div>

          <div className="flex flex-wrap">
            {/* wrapped button container */}

            <div className="flex-1 flex flex-col">
              <div className="flex flex-1">
                <button className="flex-1 p-8 m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 overflow-hidden">
                  Energy: {energy} Kwh
                </button>
              </div>

              <button className="flex-1 p-8  m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Average Voltage: {vavg} V
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Average current: {iavg} A
              </button>
            </div>
            {/* some more container (added more numbers of buttons) */}
            <div className="flex-1 flex flex-col">
              <button className="flex-1 p-8  m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Power: {pow} Kw
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg  bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Frequency: {freq} Hz
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Power factor: {pf}
              </button>
            </div>
          </div>
        </div>
        {/* my button container */}
        <div className="bg-white-500 p-8 flex-1  shadow-blue-500/50 m-2 rounded-lg shadow-lg ">
          <h2 className="text-center font-bold text-lg">Analytical Data</h2>
          <div className="flex flex-wrap justify-center items-center">
            <button className="flex-1 p-8 m-2 rounded-lg bg-indigo-500 hover:bg-red-400 text-white hover:text-black font-bold shadow-lg shadow-blue-500/30 overflow-hidden text-2xl font-serif">
              Product Count: <span className="font-sans">{displayCount}</span>{" "}
              Nos.
            </button>
          </div>
          <div className="flex flex-wrap">
            {/* wrapped button container */}
            <div className="flex-1 flex flex-col">
              <Link to="/Activetime">
                <div className="flex flex-1">
                  <button className="flex-1 p-8 m-2 rounded-lg bg-blue-300 hover:bg-red-400 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 overflow-hidden">
                    Active Time: {activeCounter} mins
                  </button>
                </div>
              </Link>

              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Idle Time: {idleCounter} mins
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-red-400 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Breakdown Time: {breakdownCounter} mins
              </button>
              {/* <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Total Pouring Time: {pouringCount} mins
              </button> */}
            </div>
            {/* some more container (added more numbers of buttons) */}
            <div className="flex-1 flex flex-col">
              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Total M/c Time: {pfinal.length} mins
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-red-400 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Total Logs: {pfinal.length}
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Energy Consumed: {energyConsumed} Units
              </button>
              {/* <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-red-400 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Total Cycle time: {cycleCount} mins
              </button> */}
            </div>
          </div>
        </div>
        {/* <div className="bg-green-500 p-8 ">pqe</div> */}
      </div>

      {/* bar-chart container - the third part*/}
      <div className="bg-gray-100 2xl:h-1/2 p-5  shadow-blue-500/50 m-2 rounded-lg shadow-lg">
        <div className="flex flex-col flex-wrap align-middle">
          <h2 className="text-center font-bold text-lg">
            Energy Consumption/Hour
            {/* Operating Mode */}
          </h2>
          <Barchart pfinal={energyPerHour} tfinal={timeHour} divider={1} />
          {/* <Barchart pfinal={cyclemodearr} tfinal={tfinal} divider={60} /> */}
          <div className="flex flex-wrap items-center justify-center">
            <Link to="/Graphs">
              <button className="bg-blue-400 rounded-lg w-56 p-3 m-2">
                Get Detailed Graph
              </button>
            </Link>
            <Link to="/">
              <button className="bg-blue-400 rounded-lg w-40 p-3 m-2">
                Logout
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});

export const pfinal = Container.pfinal;
export const tfinal = Container.tfinal;
export const mycontext = createContext(Container.pfinal);
export const tmycontext = createContext(Container.tfinal);

export default Container;
