import React, { useState, memo } from "react";
import { Link } from "react-router-dom";
import Dec2bin from "./Dec2bin";
import Decrypt from "./Decrypt";
import Barchart from "./Barchart";

const Sh2Container = memo(() => {
  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;
    hours = (hours % 12) + (isPM ? 12 : 0);
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;
    return time24;
  }

  let [msgArray, setmsgArray] = useState([]);
  let [timearray, settimearray] = useState([]);
  let [pdata2, setPdata2] = useState([]);
  let [edata2, setedata2] = useState([]);
  let [power, setpower] = useState([]);
  let [time, settime] = useState([]);
  let [opm, setopm] = useState([]);
  let [energy, setenergy] = useState([]);

  let [op, setOp1] = useState([]);
  let [productCount, setproductCount] = useState([]);
  // let [op2, setOp2] = useState([]);

  const selectedDate = JSON.parse(localStorage.getItem("selectedDate"));
  //console.log(selectedDate);

  msgArray = [];
  timearray = [];
  pdata2 = [];
  edata2 = [];
  power = [];
  time = [];
  energy = [];
  let opdata = [];
  let oparr = [];
  let pc = [];
  productCount = [];
  const log2 = JSON.parse(localStorage.getItem("log2"));
  console.log(log2);
  for (let i = 0; i < log2.length; i++) {
    const msgString = log2[i].msg;
    msgArray = stringToArray(msgString);
    // console.log(msgArray.length);

    const timestring = new Date(log2[i].log_time).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Kolkata",
    });

    timearray.push(convertTo24Hour(timestring));
    //console.log(timearray);

    // power calculation
    const result = [];
    let st = Number(msgArray[168]);
    let nd = Number(msgArray[169]);
    let rd = Number(msgArray[170]);
    let th = Number(msgArray[171]);

    Number(
      result.push(Decrypt(Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd))))
    ).toFixed(2);

    let rdata2 = Number(result[0]).toFixed(0);
    pdata2.push(rdata2);
    //console.log(pdata2);
    //power calc ends here

    //energy calculation

    const energyresult = [];
    st = Number(msgArray[0]);
    nd = Number(msgArray[1]);
    rd = Number(msgArray[2]);
    th = Number(msgArray[3]);

    Number(
      energyresult.push(
        Decrypt(Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd)))
      )
    ).toFixed(2);

    let e = Number(energyresult[0]).toFixed(0);
    edata2.push(e);

    //energy calc ends here

    if (pdata2[i] === "NaN") {
      pdata2[i] = "0";
    }

    let cm = Number(msgArray[352]) * 256 + Number(msgArray[353]);
    let hold = Number(msgArray[354]) * 256 + Number(msgArray[355]);
    let bt = Number(msgArray[356]) * 256 + Number(msgArray[357]);
    let bd = Number(msgArray[358]) * 256 + Number(msgArray[359]);
    let shutd = Number(msgArray[360]) * 256 + Number(msgArray[361]);
    let oth = Number(msgArray[362]) * 256 + Number(msgArray[363]);

    // console.log(Number(msgArray[352]) * 256 + Number(msgArray[353]), i);

    oparr = [cm, hold, bt, bd, shutd, oth];
    // console.log(oparr);
    // console.log("hi");

    opdata.push(oparr);

    /** product count*/
    let value1 = Number(msgArray[384]) * 256 + Number(msgArray[385]);
    let value2 = Number(msgArray[386]) * 256 + Number(msgArray[387]);
    let finalCount = Number(value1) * 65536 + Number(value2);
    let counter = Math.round(finalCount / 2);
    // console.log(counter);

    pc.push(counter);
    /** product count */

    if (timearray[i] >= "08:00:00" && timearray[i] < "20:00:00") {
      power.push(pdata2[i]);
      time.push(timearray[i]);
      energy.push(edata2[i]);
      opm.push(opdata[i]);
      productCount.push(pc[i]);
    }
  }
  /***SHIFT-II POWER ENERGY & TIME***/
  /*
  console.log(power);
  console.log(time);
  console.log(energy); 
  
  */
  console.log(productCount);
  console.log(energy);

  let displayCount = productCount[productCount.length - 1] - productCount[0];
  console.log(displayCount);

  console.log(opm);
  let cyclearr = [];
  let pouringarr = [];
  let cycleCount = 0;
  let pouringCount = 0;
  for (let i = 0; i < opm.length; i++) {
    cyclearr.push(opm[i][0]);
    pouringarr.push(opm[i][1]);
    if (opm[i][0] > 500) {
      cycleCount++;
    }
    if (opm[i][1] > 500) {
      pouringCount++;
    }
  }
  console.log(cyclearr, cycleCount);
  console.log(pouringarr, pouringCount);

  //making power and time global so that it is accessible to graphssh2 component.
  if (power) {
    localStorage.setItem("power", JSON.stringify(power));
  }
  //console.log(localStorage.getItem("power"));

  if (time) {
    localStorage.setItem("time", JSON.stringify(time));
  }
  //console.log(localStorage.getItem("time"));

  let activeCounter = 0;
  let idleCounter = 0;
  let breakdownCounter = 0;

  let energyPerMinute = [];

  for (let i = 0; i < power.length; i++) {
    if (power[i] > 500) {
      activeCounter++;
    } else if (power[i] > 1 && power[i] < 500) {
      idleCounter++;
    } else {
      breakdownCounter++;
    }

    if (i < power.length - 1) {
      energyPerMinute.push(energy[i + 1] - energy[i]);
    }
  }
  let timeHourly = [];
  let energyPerHour = [];

  for (let i = 0; i < power.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, power.length - 1);
    energyPerHour.push(energy[end] - energy[start]);
    timeHourly.push(time[end]);
  }
  console.log(energyPerHour);
  console.log(timeHourly);

  let eindex = energy.length - 1;

  let energyConsumed = energy[eindex] - energy[0];

  const resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  console.log(resultlatest);

  let [operatingMode, setOperatingmode] = useState([]);
  operatingMode = JSON.parse(localStorage.getItem("operatingMode")) || [];
  console.log(operatingMode);

  let currentMode = [
    "Cycle Mode", //green
    "Pouring Mode", //,safron
    "Breaktime", //yellow
    "Breakdown Mode", //red dark
    "Shutdown Mode", //red light
    "Others", //blue
  ];
  let mode;
  for (let i = 0; i < operatingMode.length; i++) {
    if (operatingMode[i] > 1000) {
      // console.log(currentMode[i]);
      mode = currentMode[i];
    }
  }
  console.log(mode);

  let [curr_energy, setCurrEnergy] = useState();
  let [vavg, setVavg] = useState();
  let [iavg, setIavg] = useState();
  let [pf, setPf] = useState();
  let [freq, setFreq] = useState();
  let [pow, setPow] = useState();

  curr_energy = resultlatest[0];
  vavg = resultlatest[20];
  iavg = resultlatest[28];
  pf = resultlatest[33];
  freq = resultlatest[35];
  pow = resultlatest[42];

  setInterval(() => {
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let operatingMode = JSON.parse(localStorage.getItem("operatingModeF2"));
    curr_energy = resultlatest[0];
    vavg = resultlatest[20];
    iavg = resultlatest[28];
    pf = resultlatest[33];
    freq = resultlatest[35];
    pow = resultlatest[42];

    setCurrEnergy(curr_energy);
    setVavg(vavg);
    setIavg(iavg);
    setFreq(freq);
    setPf(pf);
    setPow(pow);

    setOperatingmode(operatingMode);
  }, 10000);

  let buttonClass = "";
  switch (mode) {
    case "Cycle Mode":
      buttonClass = "bg-green-500 text-white shadow-green-300/30 ";
      break;
    case "Pouring Mode":
      buttonClass = "bg-orange-500 shadow-yellow-500/40";
      break;
    case "Breaktime":
      buttonClass = "bg-yellow-500 shadow-orange-300/30 ";
      break;
    case "Breakdown Mode":
      buttonClass = "bg-red-500 shadow-yellow-500/30 text-white";
      break;
    case "Shutdown Mode":
      buttonClass = "bg-red-400 shadow-orange-300/30 ";
      break;
    case "Others":
      buttonClass = "bg-blue-500 shadow-blue-500/30 text-white ";
      break;
    default:
      buttonClass = "bg-blue-400 ";
      mode = "Device Inactive";
  }

  return (
    <div>
      <div className="bg-gray-100 2xl:h-1/6 p-5  shadow-blue-500/50 m-2 rounded-lg shadow-lg">
        <div className="flex justify-center align-middle flex-wrap">
          <div className="flex flex-wrap justify-end ">
            <div>
              <Link to="/Container.js">
                <button className="p-2 flex-1 bg-green-400 text-center font-bold text-lg m-2 ease-out transform hover:scale-110 transition duration-500 hover:bg-green-900 text-black hover:text-white rounded-lg">
                  Shift-I
                </button>
              </Link>
            </div>
            <div>
              <Link to="/Sh2Container.js">
                <button className="p-2 flex-1 bg-red-700 text-center font-bold text-lg m-2 ease-out transform hover:scale-110 transition duration-500 hover:bg-green-900 text-white hover:text-black rounded-lg border-black">
                  Shift-II
                </button>
              </Link>
            </div>
          </div>
          <div>
            <h2 className="text-xl font-bold text-center p-4 border-black">
              Date: {selectedDate}
            </h2>
          </div>
        </div>
      </div>
      {/* 2 containers side by side */}
      <div className="flex flex-wrap bg-gray-100">
        {/* my piechart container */}
        <div className="bg-white-500 p-8 flex-1  shadow-blue-500/50 m-2 rounded-lg shadow-lg">
          {/* <Gaugechart /> */}
          {/* <div id="data"></div> */}
          <h2 className="text-center font-bold text-lg">Real Time Data</h2>
          {/* mode button below */}
          <div className="flex flex-wrap justify-center items-center">
            <button
              className={`flex-1 p-8 m-2 rounded-lg ${buttonClass} text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 overflow-hidden text-2xl font-serif`}
            >
              {mode}
            </button>
          </div>
          <div className="flex flex-wrap">
            {/* wrapped button container */}
            <div className="flex-1 flex flex-col">
              <div className="flex flex-1">
                <button className="flex-1 p-8 m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 overflow-hidden">
                  Energy: {curr_energy} Kwh
                </button>
              </div>

              <button className="flex-1 p-8  m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Average Voltage: {vavg} V
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Average current: {iavg} A
              </button>
            </div>
            {/* some more container (added more numbers of buttons) */}
            <div className="flex-1 flex flex-col">
              <button className="flex-1 p-8  m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Power: {pow} Kw
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg  bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Frequency: {freq} Hz
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-red-400 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Power factor: {pf}
              </button>
            </div>
          </div>
        </div>
        {/* my button container */}
        <div className="bg-white-500 p-8 flex-1  shadow-blue-500/50 m-2 rounded-lg shadow-lg ">
          <h2 className="text-center font-bold text-lg">Analytical Data</h2>
          <div className="flex flex-wrap justify-center items-center">
            <button className="flex-1 p-8 m-2 rounded-lg bg-indigo-500 hover:bg-red-400 text-white hover:text-black font-bold shadow-lg shadow-blue-500/30 overflow-hidden text-2xl font-serif">
              Product Count: <span className="font-sans">{displayCount}</span>{" "}
              Nos.
            </button>
          </div>
          <div className="flex flex-wrap">
            {/* wrapped button container */}
            <div className="flex-1 flex flex-col">
              <div className="flex flex-1">
                <button className="flex-1 p-8 m-2 rounded-lg bg-blue-300 hover:bg-red-400 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 overflow-hidden">
                  Active Time: {activeCounter} mins
                </button>
              </div>

              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Idle Time: {idleCounter} mins
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-red-400 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Breakdown Time: {breakdownCounter} mins
              </button>
            </div>
            {/* some more container (added more numbers of buttons) */}
            <div className="flex-1 flex flex-col">
              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Total M/c Time: {power.length} mins
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-red-400 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Total Logs: {power.length}
              </button>
              <button className="flex-1 p-8  m-2 rounded-lg bg-blue-300 hover:bg-blue-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30">
                Energy Consumed: {energyConsumed} Units
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* energy grapgh container */}
      <div className="bg-gray-100 2xl:h-1/2 p-5  shadow-blue-500/50 m-2 rounded-lg shadow-lg">
        <div className="flex flex-col flex-wrap align-middle">
          <h2 className="text-center font-bold text-lg">
            Energy Consumption/Hour
          </h2>
          {/* <Barchart pfinal={energyPerMinute} tfinal={time} divider={10} /> */}
          <Barchart pfinal={energyPerHour} tfinal={timeHourly} divider={1} />
          {/* <Barchart pfinal={cyclearr} tfinal={time} divider={10} /> */}
          <div className="flex flex-wrap items-center justify-center">
            <Link to="/GraphsSh2">
              <button className="bg-blue-400 rounded-lg w-56 p-3">
                Get Detailed Graph
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Sh2Container;
